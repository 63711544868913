import React, { useContext, useEffect, useState } from "react";
import "./categoryItems.scss";
import { Card } from "react-bootstrap";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { CustomContext } from "../../components/contexts/ProductUsersContext";
import { BASE_URL } from "../../utils/Constants";

function CategoryItems() {
  const {
    categoryProducts,
    setCategoryProducts,
    addToBacked,
    removeBucket,
    order,
  } = useContext(CustomContext);
  const { id } = useParams();

  const [addedProducts, setAddedProducts] = useState([]);

  useEffect(() => {
    axios
      .post(`${BASE_URL}/local/API/v0.1/shop/section/products/get/`, {
        sectionId: id,
      })
      .then((response) => {
        setCategoryProducts(response.data.products);
      });
  }, [id]);

  useEffect(() => {
    const addedProductIds = order.map((product) => product.id);
    setAddedProducts(addedProductIds);
  }, [order]);

  console.log(categoryProducts);
  return (
    <div className="content_block">
      <div className="page_title">
        <h1>{categoryProducts[0]?.sectionName}</h1>
      </div>
      <div className="categoryItems">
        {categoryProducts.map((product) => (
          <Card style={{ width: "18rem" }} key={product.id}>
            <Card.Img variant="top" src={product.picture} />
            <Card.Body>
              <Card.Title>
                <Link to={`/product/${product.id}`}>{product.name}</Link>
              </Card.Title>
              <Card.Text>
                <div className="priceBox">
                  <span className="price">{product.price}</span>
                  <span className="action">{product.oldPrice}</span>
                </div>
                <button
                  className={`btnBucket ${
                    addedProducts.includes(product.id) ? "added" : ""
                  }`}
                  onClick={() => {
                    if (addedProducts.includes(product.id)) {
                      // Удаляем товар из корзины
                      console.log("Удаление из корзины", product);
                      removeBucket(product.id);
                    } else {
                      // Добавляем товар в корзину
                      console.log("Добавление в корзину", product);
                      addToBacked({
                        id: product.id,
                        name: product.name,
                        price: product.price,
                        oldPrice: product.oldPrice,
                        picture: product.detailPicture,
                        sku: product.SKU,
                      });
                    }
                  }}
                >
                  {/* {addedProducts.includes(product.id) ? "Удалить" : "Купить"} */}
                </button>
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default CategoryItems;
