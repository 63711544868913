import React, { useContext, useEffect, useState } from "react";
import "./carts.scss";
import { Link } from "react-router-dom";
import { CustomContext } from "../../components/contexts/ProductUsersContext";
import { EmptyCart } from "../emptyCart/EmptyCart";
import { BASE_URL } from "../../utils/Constants";

function Carts() {
  const { order, removeBucket, addToBacked, incQuantity, decQuantity, setProducts } = useContext(CustomContext);

  // Локальное состояние для корзины
  const [localCartItems, setLocalCartItems] = useState(() => {
    const storedItems = localStorage.getItem("cartItems");
    return storedItems ? JSON.parse(storedItems) : [];
  });

  // Инициализация глобальной корзины из localStorage при первом рендере
  useEffect(() => {
    if (localCartItems.length > 0) {
      localCartItems.forEach((item) => {
        if (!order.some((existingItem) => existingItem.id === item.id)) {
          addToBacked(item);  // Синхронизируем локальную корзину с глобальным состоянием
        }
      });
    }
  }, [addToBacked, order, localCartItems]);

  // Синхронизация глобального состояния с локальным и localStorage
  useEffect(() => {
    if (order.length > 0) {
      setLocalCartItems(order);  // Обновляем локальное состояние при изменении глобального состояния
      localStorage.setItem("cartItems", JSON.stringify(order));  // Сохраняем глобальное состояние в localStorage
    } else {
      localStorage.removeItem("cartItems");
    }
  }, [order]);

  // Подсчет общей стоимости
  const totalPrice = localCartItems.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  );

  // Удаление товара из корзины и localStorage
  const handleRemoveItem = (id) => {
    const updatedCart = localCartItems.filter((item) => item.id !== id);
    setLocalCartItems(updatedCart);  // Обновляем локальное состояние
    setProducts(updatedCart);  // Обновляем глобальное состояние
    removeBucket(id);  // Удаляем из глобального состояния
    if (updatedCart.length > 0) {
      localStorage.setItem("cartItems", JSON.stringify(updatedCart));  // Обновляем localStorage
    } else {
      localStorage.removeItem("cartItems");
    }
  };

  // Увеличение количества товара
  const handleIncreaseQuantity = (id) => {
    const updatedCart = localCartItems.map((item) =>
      item.id === id ? { ...item, quantity: item.quantity + 1 } : item
    );
    setLocalCartItems(updatedCart);  // Обновляем локальное состояние
    incQuantity(id);  // Увеличиваем количество в глобальном состоянии
    setProducts(updatedCart);  // Синхронизируем глобальное состояние
  };

  // Уменьшение количества товара
  const handleDecreaseQuantity = (id) => {
    const updatedCart = localCartItems
      .map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
      .filter((item) => item.quantity > 0);
    setLocalCartItems(updatedCart);  // Обновляем локальное состояние
    if (order.some((item) => item.id === id && item.quantity > 1)) {
      decQuantity(id);  // Уменьшаем количество в глобальном состоянии
      setProducts(updatedCart);  // Синхронизируем глобальное состояние
    } else {
      handleRemoveItem(id);  // Удаляем товар, если количество стало 0
    }
  };

  return localCartItems.length === 0 ? (
    <EmptyCart />
  ) : (
    <>
      <div className="page_title">
        <h1>Корзина</h1>
      </div>
      <div className="basket">
        <div className="mainBox">
          {localCartItems.map((item) => (
            <div className="carts" key={item.id}>
              <div className="imageBox">
                <img src={BASE_URL + item.picture} alt={item.name} />
              </div>
              <div className="title">
                <h3>{item.name}</h3>
              </div>
              <div className="priceBox">
                {item.price === item.oldPrice ? (
                  <span className="action">{item.oldPrice} р</span>
                ) : (
                  <>
                    <span className="price">{item.oldPrice} р</span>{" "}
                    <span className="action">{item.price} р</span>{" "}
                  </>
                )}
              </div>
              <div className="amounts">
                <button
                  className="btnMinus"
                  onClick={() =>
                    item.quantity <= 1
                      ? handleRemoveItem(item.id)
                      : handleDecreaseQuantity(item.id)
                  }
                >
                  -
                </button>
                <span className="amount">{item.quantity}</span>
                <button
                  className="btnPlus"
                  onClick={() => handleIncreaseQuantity(item.id)}
                >
                  +
                </button>
              </div>
              <div className="buttons">
                <button
                  className="delete"
                  onClick={() => handleRemoveItem(item.id)}
                >
                  Удалить
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="checkout">
          <div className="titleSum">
            <div>Итого:</div>
            <div>{totalPrice} руб.</div>
          </div>
          <div className="buttonBox">
            <Link to="/ordering">
              <button className="buy">Оформить заказ</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Carts;
