import {
  FaUser,
  FaHistory,
  FaEdit,
  FaPlus,
  FaUserFriends,
  FaListAlt,
  FaBoxOpen,
  FaSignOutAlt,
} from "react-icons/fa";

export const BASE_URL = "https://shop.stanki05.ru";

export const sidebarMenu = [
  { id: 1, title: "Профиль", url: "", icon: "profile" },
  { id: 2, title: "История", url: "profile/history", icon: "history" },
  { id: 3, title: "Редактировать", url: "profile/edit", icon: "edit" },
  { id: 4, title: "Профили заказов", url: "profile/orders", icon: "orders" },
  // {
  //   id: 5,
  //   title: "История заказов",
  //   url: "profile/history/order",
  //   icon: "order",
  // },
  // { id: 6, title: "Выход", url: "profile/goods", icon: "logout" },
];

// TEST

export const iconMap = {
  profile: <FaUser />,
  history: <FaHistory />,
  edit: <FaEdit />,
  create: <FaPlus />,
  orders: <FaUserFriends />,
  order: <FaListAlt />,
  goods: <FaBoxOpen />,
  logout: <FaSignOutAlt />,
};
