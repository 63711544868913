import React from "react";
import "./edit.scss";
import fioIMG from "../../image/menuItems/fio.svg";
import emailIMG from "../../image/menuItems/email.svg";
import adressIMG from "../../image/menuItems/adress.svg";
import phoneIMG from "../../image/menuItems/phone.svg";
import companyIMG from "../../image/menuItems/company.svg";

const Edit = () => {
  return (
    <div className="content_block">
      <div className="edit">
        <span className="title">Редактирование</span>

        {/* Поле для имени */}
        <div className="input-group">
          <label htmlFor="name">Имя:</label>
          <div className="input-wrapper">
            <img src={fioIMG} alt="Иконка имени" className="icon" />
            <input type="text" id="name" placeholder="Введите ваше имя" />
          </div>
        </div>

        {/* Поле для Email */}
        <div className="input-group">
          <label htmlFor="email">Email:</label>
          <div className="input-wrapper">
            <img src={emailIMG} alt="Иконка email" className="icon" />
            <input type="email" id="email" placeholder="Введите ваш email" />
          </div>
        </div>

        {/* Поле для адреса */}
        <div className="input-group">
          <label htmlFor="adress">Адрес:</label>
          <div className="input-wrapper">
            <img src={adressIMG} alt="Иконка адреса" className="icon" />
            <input type="text" id="adress" placeholder="Введите ваш адрес" />
          </div>
        </div>

        {/* Поле для номера телефона */}
        <div className="input-group">
          <label htmlFor="phone">Номер телефона:</label>
          <div className="input-wrapper">
            <img src={phoneIMG} alt="Иконка телефона" className="icon" />
            <input
              type="tel"
              id="phone"
              placeholder="Введите ваш номер телефона"
            />
          </div>
        </div>

        {/* Поле для названия компании */}
        <div className="input-group">
          <label htmlFor="company">Название компании:</label>
          <div className="input-wrapper">
            <img src={companyIMG} alt="Иконка компании" className="icon" />
            <input
              type="text"
              id="company"
              placeholder="Введите название компании"
            />
          </div>
        </div>

        {/* Кнопка для обновления */}
        <button>Обновить данные</button>
      </div>
    </div>
  );
};

export default Edit;
