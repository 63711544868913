import React, { useEffect, useState, useContext } from "react";
import "./product.scss";
import he from "he";
import bucketBtn from "../../image/menuItems/bucketBtn.svg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CustomContext } from "../../components/contexts/ProductUsersContext";
import { BASE_URL } from "../../utils/Constants";

function Product() {
  const { id } = useParams();
  const { addToBacked = Function.prototype, removeBucket = Function.prototype, order } = useContext(CustomContext);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1); // State for quantity
  const [isInCart, setIsInCart] = useState(false); // State to track if product is in cart

  useEffect(() => {
    axios
      .post(`${BASE_URL}/local/API/v0.1/shop/products/getById/`, { pId: id })
      .then((response) => {
        setProduct(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }, [id]);

  useEffect(() => {
    // Check if the product is already in the cart
    if (order.some((item) => item.id === product?.ID)) {
      setIsInCart(true);
    } else {
      setIsInCart(false);
    }
  }, [order, product]);

  if (!product) {
    return <div>Loading...</div>;
  }

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1); // Increment quantity
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1)); // Decrement but not below 1
  };

  const handleCartButtonClick = () => {
    if (isInCart) {
      // Remove from cart
      removeBucket(product?.ID);
    } else {
      // Add to cart
      addToBacked({
        id: product?.ID,
        name: product?.NAME,
        price: product?.PRICE,
        oldPrice: product?.OLD_PRICE,
        picture: product?.PREVIEW_PICTURE,
        quantity: quantity, // Pass quantity
      });
    }

    // Toggle the cart state
    setIsInCart(!isInCart);
  };

  return (
    <div className="content_block">
      <div className="product">
        <div className="main_info">
          <h3>{product?.NAME}</h3>
          <div className="productImg">
            <img src={`${BASE_URL + product?.PREVIEW_PICTURE}`} alt={product?.NAME} />
          </div>
          <div className="block-inform">
            <div className="textBox">
              <span className="mainText">
                {product?.DETAIL_TEXT
                  ? he.decode(product.DETAIL_TEXT).replace(/<\/?p>/g, " ")
                  : "На стадии заполнения"}
              </span>
            </div>
            <div className="buyBox">
              <div className="priceBuyBox">
                {product?.PRICE === product?.OLD_PRICE ? (
                  <span className="actionPrice">{product?.OLD_PRICE} руб.</span>
                ) : (
                  <>
                    <span className="actualPrice">{product?.OLD_PRICE}</span>{" "}
                    <span className="actionPrice">{product?.PRICE} руб.</span>
                  </>
                )}
              </div>
              <div className="quantityBox">
                <button onClick={handleDecrement}>-</button>
                <input type="text" value={quantity} readOnly />
                <button onClick={handleIncrement}>+</button>
              </div>
              <button
                className={`btnBucketProd ${isInCart ? "in-cart" : ""}`}
                onClick={handleCartButtonClick}
              >
                <img src={bucketBtn} alt="Add to cart" />{" "}
                {isInCart ? "В корзине" : "В корзину"}
              </button>
            </div>
          </div>
        </div>
        <div className="block-inform__text">
          <div className="tabs">
            <ul>
              <li>Описание</li>
            </ul>
          </div>
          <span className="mainText">
            {product?.DETAIL_TEXT
              ? he.decode(product.DETAIL_TEXT).replace(/<\/?p>/g, " ")
              : "На стадии заполнения"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Product;
