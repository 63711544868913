import React, { useContext, useEffect, useState } from "react";
import "./items.scss";
import { CustomContext } from "../contexts/ProductUsersContext";
import { Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../utils/Constants";

function Items() {
  const {
    productData,
    setProducts,
    addToBacked = Function.prototype,
    removeBucket = Function.prototype,
    setOriginalProductData,
    order,
  } = useContext(CustomContext);

  const [addedProducts, setAddedProducts] = useState([]);
  const [loadedProductsCount, setLoadedProductsCount] = useState(10);

  // Инициализация корзины из localStorage при первой загрузке компонента
  useEffect(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      const parsedCartItems = JSON.parse(storedCartItems);
      parsedCartItems.forEach((item) => {
        if (!order.some((existingItem) => existingItem.id === item.id)) {
          addToBacked(item); // Добавляем товары в глобальное состояние корзины
        }
      });
    }
  }, [addToBacked, order]);

  // Загрузка данных о продуктах с сервера
  useEffect(() => {
    axios
      .post(`${BASE_URL}/local/API/v0.1/shop/products/get/`, {
        limit: loadedProductsCount,
      })
      .then((response) => {
        setProducts(response.data.products);
        setOriginalProductData(response.data.products);
      });
  }, [loadedProductsCount]);

  // Синхронизация глобального состояния order с локальным состоянием addedProducts
  useEffect(() => {
    const addedProductIds = order.map((product) => product.id);
    setAddedProducts(addedProductIds);
  }, [order]);

  // Синхронизация корзины с localStorage при изменении order
  useEffect(() => {
    if (order.length > 0) {
      localStorage.setItem("cartItems", JSON.stringify(order));
    } else {
      localStorage.removeItem("cartItems"); // Удаляем данные из localStorage, если корзина пуста
    }
  }, [order]);

  // Функция для обработки добавления или удаления товара в корзину
  const handleAddToCart = (product) => {
    const existingProduct = order.find((item) => item.id === product.id);

    if (existingProduct) {
      // Удаление товара из корзины и localStorage
      removeBucket(product.id);
      const updatedCart = order.filter((item) => item.id !== product.id);
      localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    } else {
      // Добавление товара в корзину и localStorage
      const newProduct = {
        id: product.id,
        name: product.name,
        price: product.price,
        oldPrice: product.oldPrice,
        picture: product.detailPicture,
        sku: product.SKU,
        quantity: 1, // Устанавливаем количество 1 при первом добавлении
      };
      addToBacked(newProduct);

      const updatedCart = [...order, newProduct];
      localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    }
  };

  // Функция для загрузки большего количества продуктов
  const loadMoreProducts = () => {
    setLoadedProductsCount((prevCount) => prevCount + 10);
  };

  return (
    <div className="items-container">
      <div className="content_block">
        <div className="top_block">
          <h3>Лучшие предложения</h3>
        </div>
        <div className="items">
          {productData.map((product) => (
            <Card key={product.id}>
              <div className="cardimgbox">
                <Card.Img
                  variant="top"
                  src={`${BASE_URL + product.detailPicture}`}
                />
              </div>
              <Card.Body>
                <Card.Title>
                  <Link to={`product/${product.id}`}>{product.name}</Link>
                </Card.Title>
                <Card.Text>
                  <div className="priceBox">
                    {product.price === product.oldPrice ? (
                      <span className="action">{product.oldPrice} р</span>
                    ) : (
                      <>
                        <span className="price">{product.oldPrice} р</span>{" "}
                        <span className="action">{product.price} р</span>{" "}
                      </>
                    )}
                  </div>
                  <button
                    className={`btnBucket ${
                      addedProducts.includes(product.id) ? "added" : ""
                    }`}
                    onClick={() => handleAddToCart(product)}
                  >
                    {addedProducts.includes(product.id) ? "Удалить" : "Купить"}
                  </button>
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
        <div className="load-more">
          <button className="load-more-btn" onClick={loadMoreProducts}>
            Загрузить ещё
          </button>
        </div>
      </div>
    </div>
  );
}

export default Items;
